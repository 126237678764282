import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';

import { useMutation, useQuery } from '@apollo/client';
import {
  CurrencyBoostType,
  GetAppBundlesNamesAndTagsQuery,
  LevelRewardInputType,
  PurchaseLimitEnum,
  SaleBundleConfig,
  SaleEventAssetType,
} from '../../../../../../__gqltypes__';
import AssetDisplay from '../../Items/components/AssetDisplay';
import DragUpload from '../../Items/components/DragUpload';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { APP_BUNDLES_NAMES_AND_TAGS, UPLOAD_SALE_EVENT_ASSET } from '../graphql';

type Props = {
  fetchedBundle: SaleBundleConfig;
  bundleSales: {
    id: string;
    cost: number;
    sku: string;
    name: string;
  }[];
  bundleIndex: number;
  saleEventId: string;
  handleBundleUpdate: (updatedSale: SaleBundleConfig) => SaleBundleConfig;
  handleBundleDelete: (deleteSale: SaleBundleConfig) => void;
};

const bundleItemTypes = [
  { name: 'Diamonds', value: LevelRewardInputType.CURRENCY_DIAMOND },
  { name: 'Coins', value: LevelRewardInputType.CURRENCY_COIN },
  { name: 'Remove2', value: CurrencyBoostType.REMOVE2 },
  { name: 'Team spirit', value: CurrencyBoostType.TEAM_SPIRIT },
  { name: 'Revive', value: CurrencyBoostType.REVIVE },
  { name: 'Ninja', value: CurrencyBoostType.NINJA },
  { name: 'Bomb', value: CurrencyBoostType.BOMB },
  { name: 'Speedster', value: CurrencyBoostType.SPEEDSTER },
  { name: 'Artist only', value: CurrencyBoostType.ARTIST_ONLY },
  { name: 'Hurdle hint', value: CurrencyBoostType.HURDLE_HINT },
  { name: 'Frame', value: LevelRewardInputType.PROFILE_FRAME },
  { name: 'Sticker', value: LevelRewardInputType.STICKER },
  { name: 'Vinyl', value: LevelRewardInputType.APP_SKIN },
  { name: 'Playlist', value: LevelRewardInputType.CONTENT_PACK_REWARD },
  { name: 'Badge', value: 'BADGE' },
  { name: 'Bronze Meta Fragment', value: LevelRewardInputType.CURRENCY_META_FRAGMENT_BRONZE },
  { name: 'Silver Meta Fragment', value: LevelRewardInputType.CURRENCY_META_FRAGMENT_SILVER },
  { name: 'Gold Meta Fragment', value: LevelRewardInputType.CURRENCY_META_FRAGMENT_GOLD },
  { name: 'Bronze Shard', value: LevelRewardInputType.CURRENCY_SHARD_BRONZE },
  { name: 'Silver Shard', value: LevelRewardInputType.CURRENCY_SHARD_SILVER },
  { name: 'Gold Shard', value: LevelRewardInputType.CURRENCY_SHARD_GOLD },
];
const bundleDiscounts = [
  { name: 'None', value: 0 },
  { name: '10%', value: 10 },
  { name: '20%', value: 20 },
  { name: '30%', value: 30 },
  { name: '40%', value: 40 },
  { name: '50%', value: 50 },
  { name: '60%', value: 60 },
  { name: '70%', value: 70 },
  { name: '80%', value: 80 },
  { name: '90%', value: 90 },
];
export function BundleRow({
  fetchedBundle,
  bundleSales,
  bundleIndex,
  saleEventId,
  handleBundleUpdate,
  handleBundleDelete,
}: Props) {
  const [uploadBackgroundAsset, { loading: uploadBackgroundLoading }] = useMutation(UPLOAD_SALE_EVENT_ASSET, {
    onCompleted: ({ uploadSaleEventAsset: { saleEvent } }) => {
      setBundle({
        ...bundle,
        backgroundImage: saleEvent.packs.find((pack: SaleBundleConfig) => pack.id === bundle.id).backgroundImage,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { bundleNames, bundleTags } =
    useQuery<GetAppBundlesNamesAndTagsQuery>(APP_BUNDLES_NAMES_AND_TAGS).data?.liveOps ?? {};

  /**
   * STATE
   */
  const [bundle, setBundle] = useState<SaleBundleConfig>(fetchedBundle);
  const [showBackgroundImageDeletionWarning, setShowBackgroundImageDeletionWarning] = useState<boolean>(false);

  /**
   * CHANGES HANDLERS FUNCTIONS
   */

  const handleChangeTag = (event: ChangeEvent) => {
    const newTag = (event.target as HTMLSelectElement).value;
    setBundle({
      ...bundle,
      tag: newTag,
    });
  };

  useEffect(() => {
    handleBundleUpdate(bundle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle]);

  useEffect(() => {
    setBundle(fetchedBundle);
  }, [fetchedBundle]);

  const handleBackgroundImageUpload = useCallback(
    (file) => {
      uploadBackgroundAsset({
        variables: {
          input: {
            file,
            saleEventId,
            type: SaleEventAssetType.BUNDLE,
            bundleId: bundle.id,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saleEventId, uploadBackgroundAsset]
  );

  const handleAddBundleItem = () => {
    setBundle({
      ...bundle,
      items: bundle.items
        ? [...bundle.items, { __typename: 'BundleItem', itemId: '', amount: 0, type: bundleItemTypes[0].value }]
        : [{ __typename: 'BundleItem', itemId: '', amount: 0, type: bundleItemTypes[0].value }],
    });
  };

  const handleChangeBundleId = (event: ChangeEvent) => {
    setBundle({
      ...bundle,
      skuId: (event.target as HTMLSelectElement).value,
    });
  };

  const checkIfNumber = (val: string): number | undefined => {
    return Number.isNaN(+val) ? undefined : +val;
  };

  const newPrice = bundleSales.find((b) => b.id === bundle.skuId)?.cost;

  return (
    <>
      <tr key={`${bundle.id}-${bundleIndex}`}>
        <td style={{ verticalAlign: 'middle', width: '20%' }}>
          <Form.Label className="font-weight-bold">Order</Form.Label>
          <Form.Control
            name="tag"
            as="select"
            value={bundle.skuId ?? bundleSales[0].id}
            onChange={handleChangeBundleId}
          >
            {bundleSales.map(({ id, name }) => (
              <option key={`${bundle.id}-tag-${name}`} value={id}>
                {name}
              </option>
            ))}
          </Form.Control>
        </td>
        <td style={{ verticalAlign: 'middle', width: '30%' }}>
          <Form.Label className="font-weight-bold">Name</Form.Label>
          <Form.Control
            name="tag"
            as="select"
            value={bundle.name ?? ''}
            onChange={(e) => {
              setBundle({ ...bundle, name: e.target.value });
            }}
          >
            {bundleNames?.map((name: string) => (
              <option key={`${bundle.id}-tag-${name}`} value={name}>
                {name}
              </option>
            ))}
          </Form.Control>
        </td>

        <td style={{ verticalAlign: 'middle', width: '20%' }}>
          <Form.Label className="font-weight-bold">Tag</Form.Label>
          <Form.Control name="tag" as="select" value={bundle.tag ?? 'None'} onChange={handleChangeTag}>
            {bundleTags &&
              ['None', ...bundleTags].map((tag: string) => (
                <option key={`${bundle.id}-tag-${tag}`} value={tag}>
                  {tag}
                </option>
              ))}
          </Form.Control>
        </td>
        <td>
          <Form.Label className="font-weight-bold">Show Timer</Form.Label>
          <Form.Check
            type="checkbox"
            checked={!!bundle.displayTimer}
            onChange={({ target }) => {
              setBundle({ ...bundle, displayTimer: target.checked });
            }}
          />
        </td>
        <td className="d-flex flex-column align-items-center">
          <Form.Label className="font-weight-bold">Delete bundle</Form.Label>
          <Button
            onClick={() => {
              handleBundleDelete(bundle);
            }}
            variant="danger"
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
      <tr>
        <td style={{ verticalAlign: 'middle', border: 'none' }} colSpan={2}>
          <Form.Label className="font-weight-bold">Purchase Limits</Form.Label>
          <Row>
            {bundle.purchaseLimitType !== PurchaseLimitEnum.UNLIMITED && (
              <Col className="px-1" sm={4}>
                <Form.Control
                  placeholder="Quantity"
                  value={bundle.ruleMaximumNumberOfClaim ?? ''}
                  onChange={(e) => handleBundleUpdate({ ...bundle, ruleMaximumNumberOfClaim: +e.target.value })}
                  type="text"
                />
              </Col>
            )}
            <Col className="px-1">
              <Form.Control
                name="tag"
                as="select"
                value={bundle.purchaseLimitType ?? PurchaseLimitEnum.PER_SALE}
                onChange={(e) => handleBundleUpdate({ ...bundle, purchaseLimitType: e.target.value as any })}
              >
                {Object.entries(PurchaseLimitEnum).map(([key, value], index) => {
                  return (
                    <option key={`${key}-${index}`} value={value}>
                      {key}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>
        </td>
        <td style={{ verticalAlign: 'middle', border: 'none' }}>
          <Form.Label className="font-weight-bold">Discount</Form.Label>
          <Form.Control
            name="tag"
            as="select"
            value={bundle.offerPercentage ?? ''}
            onChange={(e) => {
              setBundle({ ...bundle, offerPercentage: checkIfNumber(e.target.value) });
            }}
          >
            {bundleDiscounts.map(({ name, value }) => (
              <option key={`${bundle.id}-tag-${value}`} value={value}>
                {name}
              </option>
            ))}
          </Form.Control>
        </td>
        <td style={{ verticalAlign: 'middle', border: 'none' }}>
          <Form.Label className="font-weight-bold">Old price</Form.Label>
          <p>
            ${' '}
            {bundle.offerPercentage && newPrice ? ((newPrice * 100) / (100 - bundle.offerPercentage)).toFixed(2) : '--'}
          </p>
        </td>
        <td style={{ verticalAlign: 'middle', border: 'none' }}>
          <Form.Label className="font-weight-bold">New Price</Form.Label>
          <p>$ {newPrice ?? '--'}</p>
        </td>
      </tr>
      <tr>
        <td style={{ border: 'none' }} colSpan={2}>
          <Form.Label className="font-weight-bold">Bundle items</Form.Label>
          {bundle.items?.map((item, index) => {
            const showItemIdField = ['APP_SKIN', 'CONTENT_PACK_REWARD', 'STICKER', 'PROFILE_FRAME', 'BADGE'].includes(
              bundle.items[index]?.type ?? ''
            );
            return (
              <Row key={`${item.type}${index}`} className="mt-2 align-items-end">
                <Col className="px-1" sm={showItemIdField ? 3 : 9}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    name="tag"
                    as="select"
                    value={bundle.items[index]?.type ?? LevelRewardInputType.CURRENCY_DIAMOND}
                    onChange={(e) => {
                      const newType = e.target.value;

                      setBundle((prev) => {
                        const newItems = [...prev.items];
                        newItems[index].type = newType;

                        return { ...bundle, items: newItems };
                      });
                    }}
                  >
                    {bundleItemTypes.map((value, i) => {
                      return (
                        <option key={`${value.value}-${i}`} value={value.value}>
                          {value.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                {showItemIdField && (
                  <Col className="px-1">
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      value={bundle.items[index].itemId ?? ''}
                      onChange={(e) => {
                        const newID = e.target.value;

                        setBundle((prev) => {
                          const newItems = [...prev.items];
                          newItems[index].itemId = newID;
                          return { ...bundle, items: newItems };
                        });
                      }}
                      type="text"
                    />
                  </Col>
                )}
                <Col className="px-1" sm={2}>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    value={bundle.items[index].amount ?? ''}
                    onChange={(e) => {
                      const newAmount = e.target.value;
                      setBundle((prev) => {
                        const newItems = [...prev.items];
                        newItems[index].amount = checkIfNumber(newAmount) ?? 0;
                        return { ...bundle, items: newItems };
                      });
                    }}
                    type="text"
                  />
                </Col>
                <Col className="px-1" sm={1}>
                  <Button
                    onClick={() => {
                      setBundle((prev) => {
                        const newItems = [...prev.items];
                        newItems.splice(index, 1);
                        return {
                          ...prev,
                          items: newItems,
                        };
                      });
                    }}
                    variant="danger"
                  >
                    <i className="fas fa-trash" />
                  </Button>
                </Col>
              </Row>
            );
          })}
          <Button onClick={handleAddBundleItem} className="mt-3 w-50" style={{ margin: '0 20%' }}>
            Add bundle item
          </Button>
        </td>
        <td style={{ border: 'none' }} colSpan={4}>
          <Form.Label className="font-weight-bold">Background image</Form.Label>
          {bundle.backgroundImage ? (
            <AssetDisplay assetUrl={bundle.backgroundImage} />
          ) : (
            <DragUpload onUpload={handleBackgroundImageUpload} />
          )}
          <LoadingLogo show={uploadBackgroundLoading} />
          <Button
            className="mt-2"
            onClick={() => {
              setBundle({ ...bundle, backgroundImage: '' });
              setShowBackgroundImageDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showBackgroundImageDeletionWarning && (
            <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
          )}
        </td>
      </tr>
    </>
  );
}
