import { SpecialEventQuery } from '../../../../../__gqltypes__';
import './typedef';

type RankGroups = NonNullable<SpecialEventQuery['specialEvent']>['rankGroups'];

/**
 * Change a type case to match with the one used in this frontend
 */
export const getType = (type: string) => {
  switch (type) {
    case 'CurrencyCoin':
      return 'CURRENCY_COIN';
    case 'CurrencyDiamond':
      return 'CURRENCY_DIAMOND';
    case 'CurrencySpecialEventTicket':
      return 'CURRENCY_SPECIAL_EVENT_TICKET';
    case 'CurrencyBoost':
      return 'CURRENCY_BOOST';
    case 'CurrencyCategoryFragment':
      return 'CURRENCY_CATEGORY_FRAGMENT';
    case 'Sticker':
      return 'STICKER';
    case 'ProfileFrame':
      return 'PROFILE_FRAME';
    case 'AppSkin':
      return 'APP_SKIN';
    case 'MonthlyBuff':
      return 'MONTHLY_BUFF';
    case 'ContentPackReward':
      return 'CONTENT_PACK_REWARD';
    case 'CurrencySpecialEvent':
      return 'CURRENCY_SPECIAL_EVENT';
    case 'CurrencyMetaFragmentBronze':
      return 'CURRENCY_META_FRAGMENT_BRONZE';
    case 'CurrencyMetaFragmentSilver':
      return 'CURRENCY_META_FRAGMENT_SILVER';
    case 'CurrencyMetaFragmentGold':
      return 'CURRENCY_META_FRAGMENT_GOLD';
    case 'CurrencyShardBronze':
      return 'CURRENCY_SHARD_BRONZE';
    case 'CurrencyShardSilver':
      return 'CURRENCY_SHARD_SILVER';
    case 'CurrencyShardGold':
      return 'CURRENCY_SHARD_GOLD';
    default:
      return null;
  }
};

/**
 * Convert a reward from its __typename to a usable type in frontend
 * @param {{__typename: string}} reward
 * @returns {Reward}
 */
export const getReward = (reward: any) => {
  switch (reward.__typename) {
    case 'CurrencyCoin':
    case 'CurrencyDiamond':
    case 'CurrencyCategoryRandom':
    case 'CurrencyCategoryFragment':
    case 'CurrencyMetaFragmentBronze':
    case 'CurrencyMetaFragmentSilver':
    case 'CurrencyMetaFragmentGold':
    case 'CurrencyShardBronze':
    case 'CurrencyShardSilver':
    case 'CurrencyShardGold':
      return {
        itemId: null,
        type: getType(reward.__typename),
        amount: reward.amount,
      };
    case 'CurrencyBoost':
      return {
        itemId: reward.boostType,
        type: getType(reward.__typename),
        amount: reward.amount,
      };
    case 'MonthlyBuff':
      console.log('buffType', reward.buffType);
      return {
        itemId: reward.buffType,
        type: getType(reward.__typename),
        amount: reward.multiplier,
      };
    case 'ContentPackReward':
      return {
        itemId: reward.contentPack.id,
        itemName: reward.contentPack.name,
        type: getType(reward.__typename),
        amount: 1,
      };
    case 'AppSkin':
    case 'ProfileFrame':
    case 'Sticker':
      return {
        itemId: reward.id,
        type: getType(reward.__typename),
        amount: 1,
      };
    default:
      return null;
  }
};

/**
 * verify if rankgroups are overlaping. Returns an array of textual detected errors
 */
export function checkNoOverlap(rankgroups: RankGroups) {
  /**
   * @type {string[]}
   */
  const errors = [];
  // @ts-ignore
  for (const [meIndex, me] of rankgroups.entries()) {
    // @ts-ignore
    for (const [otherIndex, other] of rankgroups.slice(meIndex + 1).entries()) {
      if (
        (other.maxRank >= me.minRank && other.minRank <= me.maxRank) ||
        (other.minRank <= me.maxRank && other.maxRank >= me.minRank)
      ) {
        errors.push(
          `error between indexes ${meIndex + 1} - ${otherIndex + 2 + meIndex}:  rank group boundaries conflict`
        );
      }
    }
  }
  return errors;
}

/**
 * verify if bounds of rankgroups are correct. Returns an array of textual detected errors
 */
export function checkCorrectBounds(rankgroups: RankGroups) {
  const errors: string[] = [];
  rankgroups.forEach((rankgroup, index) => {
    if (rankgroup.minRank > rankgroup.maxRank) {
      errors.push(`error for rankgroup ${index + 1}: minimum is greater than its maximum!`);
    }
  });
  return errors;
}

/**
 * verify if rankgroups are correct. Returns an array of textual detected errors
 */
export function checkRankGroupErrors(rankgroups: RankGroups) {
  return [...checkNoOverlap(rankgroups), ...checkCorrectBounds(rankgroups)];
}

export function toGameCenterId(festivalName: string) {
  festivalName = festivalName.replace(/[^a-zA-Z0-9]/g, '_');
  festivalName = festivalName.toLowerCase();
  return `songpop.festival.${new Date().getFullYear()}.${festivalName}`;
}
